@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --foreground-rgb: 0, 0, 0;
    --color-primary-100: #00c6fa;
    --color-primary-200: #ff00a9;
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  html,
  body {
    max-width: 100vw;
    overflow-x: hidden;
    color: white;
    background-color: "black";
    font-size: 62.5%;
    scroll-behavior: smooth;
  }

  body {
    font-family: var(--Poppins-font);
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  h1,
  .h1 {
    @apply text-4xl lg:text-5xl leading-normal;
  }
  h2,
  .h2 {
    @apply text-3xl lg:text-4xl leading-normal;
  }
  h3,
  .h3 {
    @apply text-2xl lg:text-3xl leading-normal;
  }
  h4,
  .h4 {
    @apply text-xl lg:text-2xl leading-normal;
  }
  p {
    @apply text-sm lg:text-base leading-normal;
  }
}

@layer utilities {
  .bg-gradient-primary {
    background: linear-gradient(
      216.56deg,
      var(--color-primary-100) 5.32%,
      var(--color-primary-200) 94.32%
    );
  }
}
