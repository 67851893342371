html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q,
blockquote {
  quotes: none;
}
q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

@font-face {
  font-family: "Wrong Delivery";
  font-weight: 600;
  src: url("./assets/font/Wrong-Delivery.woff2") format("woff2"),
    url("./assets/font/Wrong-Delivery.woff") format("woff");
}

/**
 * !!! 変数の定義 !!!
 * 変数は作りすぎず、デザインコンポーネント、もしくはシンボルを元に作成する
 * またGoogle Font等の特殊なウェブフォントの場合、そのフォント名を定義してわかりやすくする
 */
/**
 * !!! ブレークポイント !!!
 */
@media only screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  .sp {
    display: none !important;
  }
}

::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
}

/**
 * !!! browsersyncのアラートを非表示 !!!
 */
#__bs_notify__ {
  display: none !important;
}

/**
 * !!! ページCSS基本設定 !!!
 * 基本的にこの箇所は操作しないようにする
 * ----------------ここから----------------
 */
html {
  font-size: 62.5%;
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Hiragino Sans",
    "游ゴシック", YuGothic, "メイリオ", "Meiryo", sans-serif;
  background: #000;
}

body {
  background: #000;
}

* {
  font-size: 14px;
  font-size: 1.4rem;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

a {
  color: inherit;
  text-decoration: none;
}

input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

textarea {
  resize: vertical;
}

input[type="checkbox"],
input[type="radio"] {
  display: none;
}

input[type="submit"],
input[type="button"],
label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

.row {
  overflow: hidden;
  *zoom: 1;
}

/**
 * ----------------ここまで----------------
 */
/**
 * !!! FlexBox Rule !!!
 * 都度scssを記載するのではなく、クラスで定義し、クラスの付け外しで管理すること。
 */
.flex-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

/**
 * !!! Inner Rule !!!
 * 最初にデザインを確認し、存在するinnerパターンを作成すること
 * max-width指定のみだと、画面幅を縮小するときに余白が消えるため、paddingをつけること
 * 例) innerが1000pxであれば、
 *     max-width: 1060px;
 *     padding: 0 30px;
 */
.inner {
  max-width: 1160px;
  padding: 0 30px;
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  .inner {
    padding: 0 20px;
  }
}
/* 
[data-trigger] {
  position: relative;
  top: 60px;
  -webkit-transition: top 1s, opacity 1s;
  -moz-transition: top 1s, opacity 1s;
  -o-transition: top 1s, opacity 1s;
  transition: top 1s, opacity 1s;
  opacity: 0;
}
[data-trigger].visible {
  top: 0;
  opacity: 1;
} */

@keyframes flash {
  0%,
  13%,
  27%,
  41%,
  59%,
  68%,
  72%,
  83%,
  94%,
  100% {
    opacity: 0;
  }
  9%,
  21%,
  36%,
  46%,
  63%,
  70%,
  78%,
  89% {
    opacity: 1;
  }
}

.footer__upper {
  background: linear-gradient(
    138deg,
    #d00089 0.59%,
    #471d8c 51.85%,
    #009197 100%
  );
  padding: 135px 0;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .footer__upper {
    padding: 90px 0;
  }
}
.footer__upper > img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  mix-blend-mode: saturation;
}
.footer__upper .inner {
  position: relative;
  z-index: 1;
}
.footer__upper h2 {
  text-align: center;
}
.footer__upper h2 img {
  display: inline-block;
  width: 90%;
  max-width: 771px;
}
.footer__upper p {
  margin-top: 80px;
  color: #fcfdfd;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
}
@media only screen and (max-width: 767px) {
  .footer__upper p {
    font-size: 14px;
    line-height: 2;
    margin-top: 40px;
  }
}

.footer__row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.footer__row div {
  width: 46.8181818%;
  margin-top: 74px;
}
@media only screen and (max-width: 767px) {
  .footer__row div {
    width: 100%;
    margin-top: 32px;
  }
}
.footer__row div a {
  display: block;
}
@media only screen and (min-width: 768px) {
  .footer__row div a:hover {
    animation: flash 2.5s linear infinite;
  }
}

.footer__bottom {
  background: #000;
  padding: 80px 0;
  text-align: center;
  text-align: center;
  color: #696969;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.07em;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 53.264px;
}
@media only screen and (max-width: 767px) {
  .footer__bottom {
    padding: 40px 0;
    font-size: 12px;
    line-height: 1;
  }
}
.footer__bottom div {
  line-height: 1;
  margin-top: 32px;
  font-size: 90%;
}
.footer__bottom div span {
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Hiragino Sans",
    "游ゴシック", YuGothic, "メイリオ", "Meiryo", sans-serif;
  font-weight: normal;
}
.footer__bottom div span a {
  text-decoration: underline;
}
.footer__bottom ul {
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .footer__bottom ul {
    padding-bottom: 24px;
  }
}
.footer__bottom ul li {
  display: inline-block;
  vertical-align: middle;
  padding: 0 7px;
}
.footer__bottom ul li a {
  display: block;
}
.footer__bottom ul li a svg {
  display: block;
  height: 22px;
  width: auto;
}

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  padding: 55px 80px;
}
@media only screen and (max-width: 767px) {
  .header {
    padding: 30px 20px;
  }
}
.header__row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
}
.header__brand a img {
  width: 157px;
  height: auto;
}
.header__menu {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
@media only screen and (max-width: 767px) {
  .header__menu {
    display: none;
  }
}
.header__menu li {
  padding: 0 19px;
}
.header__menu li a {
  color: #dfdde4;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}
.header__cv a {
  border-radius: 6px;
  display: block;
  width: 172px;
  text-align: center;
  display: block;
  color: #000;
  padding: 12px 0 11px;
  overflow: hidden;
  position: relative;
  background-color: #ff01a9;
}
@media only screen and (min-width: 768px) {
  .header__cv a:hover:before {
    width: 100%;
  }
  .header__cv a:hover span {
    color: #ff01a9;
  }
}
@media only screen and (max-width: 767px) {
  .header__cv a {
    display: none;
  }
}
.header__cv a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 0%;
  height: 100%;
  background-color: #fff;
  z-index: 0;
  transition: all 0.3s;
}
.header__cv a span {
  position: relative;
  z-index: 1;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  transition: all 0.3s;
  line-height: 40px;
}

.about {
  padding-top: 148px;
  padding-bottom: 148px;
  position: relative;
  border-top: 2px solid #ff01a9;
}
@media only screen and (max-width: 767px) {
  .about {
    padding: 90px 0;
  }
}
.about:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding-top: 58.9285714%;
  background-image: url(./assets/about_bg01.jpg);
  background-size: cover;
  background-position: center center;
  z-index: 0;
  pointer-events: none;
}
.about:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-top: 58.9285714%;
  background-image: url(./assets/about_bg02.jpg);
  background-size: cover;
  background-position: center center;
  z-index: 0;
  pointer-events: none;
}
.about__upper,
.about__bottom {
  position: relative;
  z-index: 1;
}
.about__upper .inner h2 svg {
  width: 364px;
  height: auto;
  display: block;
}
@media only screen and (max-width: 767px) {
  .about__upper .inner h2 svg {
    width: 70%;
    margin: auto;
  }
}
.about__upper .inner h2 span {
  display: block;
  color: #ff01a9;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  margin-top: 26px;
}
@media only screen and (max-width: 767px) {
  .about__upper .inner h2 span {
    font-size: 18px;
    line-height: 1.7;
    text-align: center;
    margin-top: 20px;
  }
}
.about__upper .inner h3 {
  color: #fcfdfd;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px;
  margin-top: 71px;
}
@media only screen and (max-width: 767px) {
  .about__upper .inner h3 {
    font-size: 24px;
    line-height: 1.8;
    margin-top: 40px;
  }
}
.about__upper .inner p {
  margin-top: 52px;
}
@media only screen and (max-width: 767px) {
  .about__upper .inner p {
    margin-top: 32px;
  }
}
.about__upper .inner p span {
  display: block;
  color: #fcfdfd;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 42px;
  margin-top: 12px;
}
@media only screen and (max-width: 767px) {
  .about__upper .inner p span {
    font-size: 14px;
    line-height: 2;
  }
}
.about__bottom {
  margin-top: 80px;
}
.about__bottom .inner > div {
  position: relative;
}
.about__bottom .inner > div:before {
  content: "";
  width: calc(100% - 50px);
  height: 3px;
  top: 0;
  right: 0;
  position: absolute;
  background: #0bacb3;
}
.about__bottom .inner > div:after {
  content: "";
  width: calc(100% - 50px);
  height: 3px;
  bottom: 0;
  left: 0;
  position: absolute;
  background: #0bacb3;
}
.about__bottom .inner > div > div {
  position: relative;
}
.about__bottom .inner > div > div:before {
  content: "";
  height: calc(100% - 50px);
  width: 3px;
  top: 0;
  right: 0;
  position: absolute;
  background: #0bacb3;
}
.about__bottom .inner > div > div:after {
  content: "";
  height: calc(100% - 50px);
  width: 3px;
  bottom: 0;
  left: 0;
  position: absolute;
  background: #0bacb3;
}
.about__bottom .inner > div > div > div {
  padding: 58px 60px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .about__bottom .inner > div > div > div {
    padding: 50px 20px;
  }
}
.about__bottom .inner > div > div > div:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  background-image: url(./assets/border.svg); 
  background-size: 100% 100%;
}
.about__bottom .inner > div > div > div:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background-image: url(./assets/border.svg); 
  background-size: 100% 100%;
}
.about__bottom .inner > div > div > div > div:nth-child(1) {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.about__bottom .inner > div > div > div > div:nth-child(1) > div:nth-child(1) {
  width: 39.2857143%;
}
@media only screen and (max-width: 767px) {
  .about__bottom
    .inner
    > div
    > div
    > div
    > div:nth-child(1)
    > div:nth-child(1) {
    width: 100%;
  }
}
.about__bottom .inner > div > div > div > div:nth-child(1) > div:nth-child(2) {
  width: 60.7142857%;
}
@media only screen and (max-width: 767px) {
  .about__bottom
    .inner
    > div
    > div
    > div
    > div:nth-child(1)
    > div:nth-child(2) {
    width: 100%;
  }
}
.about__bottom
  .inner
  > div
  > div
  > div
  > div:nth-child(1)
  > div:nth-child(2)
  img {
  display: block;
  height: auto;
  width: 82.6890756%;
}
@media only screen and (max-width: 767px) {
  .about__bottom
    .inner
    > div
    > div
    > div
    > div:nth-child(1)
    > div:nth-child(2)
    img {
    width: 100%;
  }
}
.about__bottom
  .inner
  > div
  > div
  > div
  > div:nth-child(1)
  > div:nth-child(2)
  p {
  display: block;
  color: #fcfdfd;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 42px;
  margin-top: 28px;
}
@media only screen and (max-width: 767px) {
  .about__bottom
    .inner
    > div
    > div
    > div
    > div:nth-child(1)
    > div:nth-child(2)
    p {
    font-size: 14px;
    line-height: 2;
    margin-top: 20px;
  }
}
.about__bottom .inner > div > div > div > div:nth-child(2) {
  margin-top: 23px;
}
.about__bottom .inner > div > div > div > div:nth-child(2) p {
  display: block;
  color: #fcfdfd;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 42px;
}
@media only screen and (max-width: 767px) {
  .about__bottom .inner > div > div > div > div:nth-child(2) p {
    font-size: 14px;
    line-height: 2;
  }
}

.faq {
  border-top: 2px solid #ff01a9;
  padding-top: 168px;
  padding-bottom: 142px;
  background-image: url(./assets/qa_bg.jpg);
  background-size: 100% auto;
  background-position: center top;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 767px) {
  .faq {
    padding: 90px 0;
  }
}
.faq h2 svg {
  width: 226px;
  height: auto;
  display: block;
}
@media only screen and (max-width: 767px) {
  .faq h2 svg {
    margin: auto;
    width: auto;
    height: 28px;
  }
}
.faq h2 span {
  display: block;
  color: #02dcdb;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  margin-top: 26px;
}
@media only screen and (max-width: 767px) {
  .faq h2 span {
    font-size: 18px;
    line-height: 1.7;
    margin-top: 20px;
  }
}
.faq__boxes {
  padding-top: 46px;
}
@media only screen and (max-width: 767px) {
  .faq__boxes {
    padding-top: 20px;
  }
}
.faq__box {
  position: relative;
  margin-top: 52px;
}
@media only screen and (max-width: 767px) {
  .faq__box {
    margin-top: 22px;
  }
}
.faq__box:before {
  content: "";
  width: calc(100% - 50px);
  height: 3px;
  top: 0;
  right: 0;
  position: absolute;
  background: #0bacb3;
}
.faq__box:after {
  content: "";
  width: calc(100% - 50px);
  height: 3px;
  bottom: 0;
  left: 0;
  position: absolute;
  background: #0bacb3;
}
.faq__box > div {
  position: relative;
}
.faq__box > div:before {
  content: "";
  height: calc(100% - 50px);
  width: 3px;
  top: 0;
  right: 0;
  position: absolute;
  background: #0bacb3;
}
.faq__box > div:after {
  content: "";
  height: calc(100% - 50px);
  width: 3px;
  bottom: 0;
  left: 0;
  position: absolute;
  background: #0bacb3;
}
.faq__box > div > div {
  padding: 48px 55px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .faq__box > div > div {
    padding: 48px 20px;
  }
}
.faq__box > div > div:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  background-image: url(./assets/border.svg); 
  background-size: 100% 100%;
}
.faq__box > div > div:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background-image: url(./assets/border.svg); 
  background-size: 100% 100%;
}
.faq__box > div > div strong {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.faq__box > div > div strong span {
  display: block;
}
.faq__box > div > div strong span:nth-child(1) {
  color: #02dcdb;
  font-family: "Wrong Delivery";
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 37.817px;
  width: 80px;
}
@media only screen and (max-width: 767px) {
  .faq__box > div > div strong span:nth-child(1) {
    width: 52px;
    font-size: 20px;
  }
}
.faq__box > div > div strong span:nth-child(2) {
  width: calc(100% - 80px);
  color: #fcfdfd;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.7;
}
@media only screen and (max-width: 767px) {
  .faq__box > div > div strong span:nth-child(2) {
    width: calc(100% - 52px);
    font-size: 18px;
  }
}
.faq__box > div > div p {
  margin-top: 26px;
  color: #fcfdfd;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 33px;
  display: none;
  padding-top: 20px;
  border-top: 1px solid #02dcdb;
}
@media only screen and (max-width: 767px) {
  .faq__box > div > div p {
    font-size: 14px;
    line-height: 2;
  }
}

.first-load {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 10000;
  display: none;
}
.first-load div {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.first-load div img {
  display: block;
  opacity: 0;
  transition: opacity 1.5s;
}
.first-load.on img {
  opacity: 1;
}

.how {
  padding-top: 168px;
  padding-bottom: 142px;
  border-top: 2px solid #ff01a9;
  background-image: url(./assets/how_bg.jpg); 
  background-size: 100% auto;
  background-position: center bottom;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 767px) {
  .how {
    padding: 90px 0;
  }
}
.how .inner {
  position: relative;
  z-index: 1;
}
.how .inner h2 svg {
  width: 689px;
  height: auto;
  display: block;
}
@media only screen and (max-width: 767px) {
  .how .inner h2 svg {
    width: 90%;
    margin: auto;
  }
}
.how .inner h2 span {
  display: block;
  color: #02dcdb;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  margin-top: 26px;
}
@media only screen and (max-width: 767px) {
  .how .inner h2 span {
    font-size: 18px;
    line-height: 1.7;
    margin-top: 20px;
    text-align: center;
  }
}
.how .inner > p {
  color: #fcfdfd;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 40px;
  margin-top: 63px;
}
@media only screen and (max-width: 767px) {
  .how .inner > p {
    font-size: 14px;
    line-height: 2;
    margin-top: 40px;
  }
}
.how .inner > div {
  border: 1px solid #ff01a9;
  padding: 36px 55px;
  border-radius: 4px;
  margin-top: 60px;
}
@media only screen and (max-width: 767px) {
  .how .inner > div {
    padding: 24px 18px;
    margin-top: 50px;
  }
}
.how .inner > div h3 {
  color: #ff01a9;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  padding-bottom: 26px;
  border-bottom: 1px solid #ff01a9;
}
@media only screen and (max-width: 767px) {
  .how .inner > div h3 {
    font-size: 20px;
    line-height: 1.476190476;
    text-align: center;
    padding-bottom: 16px;
  }
}
.how .inner > div ul {
  margin-top: 44px;
  list-style: auto;
  padding-left: 24px;
}
.how .inner > div ul li {
  color: #fcfdfd;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 40px;
}
@media only screen and (max-width: 767px) {
  .how .inner > div ul li {
    font-size: 14px;
    line-height: 2;
  }
}
.how .inner > div p {
  margin-top: 12px;
  color: #fcfdfd;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 40px;
}
@media only screen and (max-width: 767px) {
  .how .inner > div p {
    font-size: 14px;
    line-height: 2;
  }
}
.how .inner > div > div {
  margin-top: 52px;
}
@media only screen and (max-width: 767px) {
  .how .inner > div > div {
    margin-top: 32px;
  }
}
@media only screen and (max-width: 767px) {
  .how .inner > div > div ol li {
    margin-top: 16px;
  }
}
.how .inner > div > div ol li img {
  -webkit-filter: drop-shadow(0px 0px 50px rgba(2, 220, 219, 0.2));
  -moz-filter: drop-shadow(0px 0px 50px rgba(2, 220, 219, 0.2));
  filter: drop-shadow(0px 0px 50px rgba(2, 220, 219, 0.2));
}
.how .inner > div > div p {
  margin-top: 24px;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  .how .inner > div > div p {
    font-size: 14px;
  }
}

.kv {
  height: 66.66666666666666vw;
  position: relative;
  background-image: url(./assets/main.jpg); 
  background-size: cover;
  background-position: center;
}
@media only screen and (max-width: 767px) {
  .kv {
    height: 767px;
    background-size: 223% auto;
    background-position: top right 43%;
    background-repeat: no-repeat;
  }
}
.kv__blur {
  position: absolute;
  top: 10.416666666666668vw;
  right: 28.902116402116402vw;
  width: 29.464285714285715vw;
  height: 25.42989417989418vw;
}
.kv__inner {
  position: absolute;
  left: 0;
  bottom: 2vw;
  width: 100%;
  right: 0;
  margin: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 0 3.3068783068783065vw;
  align-items: center;
  margin: auto;
}
@media only screen and (max-width: 767px) {
  .kv__inner {
    display: block;
    padding: 0 16px;
    bottom: 30px;
  }
}
.kv__inner > div:nth-child(1) {
  width: 54.0368272%;
}
@media only screen and (max-width: 767px) {
  .kv__inner > div:nth-child(1) {
    width: 100%;
  }
}
.kv__inner > div:nth-child(1) img {
  display: block;
  width: 100%;
}
.kv__inner > div:nth-child(2) {
  width: 42.1388102%;
}
@media only screen and (max-width: 767px) {
  .kv__inner > div:nth-child(2) {
    width: 100%;
    margin-top: 20px;
  }
}
.kv__inner > div:nth-child(2) strong {
  display: block;
  color: #02dcdb;
  font-family: "Poppins", sans-serif;
  font-size: 1.5873015873015872vw;
  font-style: normal;
  font-weight: 900;
  line-height: 35px;
  /* 145.833% */
}
@media only screen and (max-width: 767px) {
  .kv__inner > div:nth-child(2) strong {
    color: #02dcdb;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: 25px;
  }
}
.kv__inner > div:nth-child(2) p {
  color: #02dcdb;
  font-family: "Poppins", sans-serif;
  font-size: 0.9259259259259258vw;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  margin-top: 20px;
}
@media only screen and (max-width: 767px) {
  .kv__inner > div:nth-child(2) p {
    color: #02dcdb;
    font-size: 11px;
    font-style: normal;
    line-height: 25px;
  }
}

.mods {
  padding-top: 168px;
  padding-bottom: 142px;
  background-image: url(./assets/mods_bg.jpg); 
  background-size: 100% auto;
  background-position: center top;
  background-repeat: no-repeat;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .mods {
    padding: 90px 0;
  }
}
.mods:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
  background-image: url(./assets/how_bg.jpg); 
  background-size: 100% auto;
  background-position: center bottom;
  background-repeat: no-repeat;
}
.mods h2 svg {
  width: 270px;
  height: auto;
  display: block;
}
@media only screen and (max-width: 767px) {
  .mods h2 svg {
    width: 50%;
    margin: auto;
  }
}
.mods h2 span {
  display: block;
  color: #02dcdb;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
  margin-top: 26px;
}
@media only screen and (max-width: 767px) {
  .mods h2 span {
    font-size: 18px;
    line-height: 1.7;
    margin-top: 20px;
    text-align: center;
  }
}
.mods h3 {
  background: #ff01a9;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  letter-spacing: 0.01em;
  padding: 15px 0;
  border-radius: 8px;
  margin-top: 60px;
}
.mods h3:nth-child(4) {
  margin-top: 120px;
}
.mods p {
  color: #fcfdfd;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 40px;
  margin-top: 40px;
}
@media only screen and (max-width: 767px) {
  .mods p {
    font-size: 14px;
    line-height: 2;
  }
}
.mods .inner {
  position: relative;
}
.mods .inner > div {
  max-width: 740px;
  margin: auto;
  margin-top: 40px;
}
@media only screen and (max-width: 767px) {
  .mods .inner > div {
    margin-top: 60px;
    overflow: scroll;
    margin-left: -20px;
    width: calc(100% + 40px);
    padding: 0 20px;
  }
}
.mods .inner > div img {
  display: block;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .mods .inner > div img {
    height: 270px;
    width: auto;
    max-width: 300%;
  }
}

/**

 */
body.top {
  height: 100%;
  width: 100%;
}
